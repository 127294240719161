import React from 'react'

import Layout from '../components/Layout'
import Container from '../components/Container'
import Card from '../components/Card'

import me1Image from '../assets/img/me/dog-traininer.png'
import me2Image from '../assets/img/me/dog-trainer-trick.png'
import citizenImage from '../assets/img/me/akc-citizen.png'
import apdtImage from '../assets/img/me/professional-dog-trainer-apdt.png'

import './Me.scss'

const renderAPDT = () => <a href='https://apdt.com/' target='_blank' rel='noopener noreferrer'>Association of Professional Dog Trainers A.P.D.T</a>
const renderAKC = () => <a href='https://www.akc.org/products-services/training-programs/canine-good-citizen/' target='_blank' rel='noopener noreferrer'>Canine Good Citizen Evaluator for the AKC</a>
const renderYT = () => <a href='https://www.youtube.com/user/AlmondBear' target='_blank' rel='noopener noreferrer'>YouTube channel</a>

const Me = ({ location }) =>
  <Layout
    location={location}
    title='Pawsome Dogs | Chicago Dog Training | About Me'
    description='Meet your experienced Pawsome Dogs Chicago trainer. Book a lesson today to get started!'>
    <main className='Me'>

      <Container className='Me--container'>

        <h1>Melissa Arteaga</h1>

        <section className='Me--container--bio'>
          <div className='Me--container--bio--text'>
            <img src={me1Image} alt='professional dog trainer' />
            <div>
              <p>I have loved dogs my entire life. My interest in dog training started while
                doing volunteer shelter work, where Timmy, a 4 month old pointer mix, came
                into my life. Timmy was a handful and full of energy. To keep him busy, I
                started trick training, as well as obedience work. After being attacked by
                another dog, Timmy developed dog reactivity. As a result, I dove into advanced
                behavior modification work with Timmy. While working with Timmy every day, I
                realized my true calling.</p><br />
              <p>Since then I have been professionally training dogs in group classes and
                private lessons, including leash reactivity and aggression issues. To advance
                my work, I study with other trainers, attend seminars and workshops, and stay
                up-to-date on the latest canine behavior research to provide proven and innovative
                advice. I'm a member of the {renderAPDT()} as well as a {renderAKC()}.</p><br />
            </div>
          </div>
          <p>I share my life with two dogs: Timmy and Karma (a cattledog mix). Timmy and
            Karma have a {renderYT()} (over 1,500 subscribers) showcasing all their
            tricks. Timmy has been featured in USA Weekend for his fetch me a drink trick
            and in Chicago Now for his various other tricks.</p>
        </section>

        <section className='Me--container--cred'>
          <Card>
            <h1>Career Experience</h1>
            <div className='Me--container--cred--items'>
              <div>
                <img src={me2Image} alt='dog trainer trick training' />
                <p>13+ years of experience as a Professional Dog Trainer</p>
              </div>
              <div>
                <img src={citizenImage} alt='AKC good citizen' />
                <p>AKC Canine Good Citizen Evaluator Certification as of 2012</p>
              </div>
              <div>
                <img src={apdtImage} alt='professional dog trainer APDT' />
                <p>Member of the Association of Professional Dog Trainers (APDT) as of 2010</p>
              </div>
            </div>
          </Card>
        </section>

      </Container>
    </main>
  </Layout>

export default Me
